
.buttonhandle{
    margin-left: 39rem;
    
}
  
  
  /* Responsive style for screens wider than 600px */
  @media (min-width: 600px) {
    .buttonhandle {
      margin-left: 30rem;
    }
  }
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.table-responsive.addproduct {
  height: calc(100vh - 180px);
}

.table-responsive.addproduct thead {
  position: sticky;
  background: #fff;
  top: 0;
}

.table-responsive::-webkit-scrollbar {
  width: 1em;
  height: 12px;
}

.table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #D85A4F;
  outline: 1px solid #D85A4F;
  border-radius: 5px;
}

.spinnerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.433);
  z-index: 9999999;
  transition: all 0.2s ease-in-out;

}


.spinnerWrapper .spinner-border {
  z-index: 9999999999;
  transition: all 0.2s ease-in-out;

}

.customStyles {
  background-color: '#eeeeee';
  padding: '0 0.7em';
}


.expand-sidebar.layout-menu {
  transition: all 0.3s ease;
  transform: none !important;
  position: absolute !important;
  height: 100%;

}

.expand-sidebar.layout-menu .menu-inner {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
}

#layout-menu-close {
  cursor: pointer;
  position: relative;
  display: inline-block;
  z-index: 999;
  transform: translateX(50%);
}

#layout-menu-close i {
  color: #ffffff;
  font-size: 30px !important;
}

#layout-menu-close::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #D85A4F;
  z-index: -4;
  border-radius: 50px;

}

#layout-menu {
  transition: all 0.3s ease !important;
}

.toast.bs-toast {
  z-index: 99999999999999;
}
.bx-lg {
  font-size: 1.5rem !important;
}