
.clor-picker{
    padding: 0;
    list-style: none;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.clor-picker li{
    border-radius: 5px;
    color: #fff;
    font-size: 10px;
    padding: 3px;

}